import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container,
  Box,
  Text,
  Switch,
  useToast,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  FormLabel,
  Select,
  InputRightAddon,
  InputLeftAddon,
  Input,
  InputGroup,
  Stack,
  FormControl,
  FormHelperText,
  Flex,
  VStack,
  Checkbox,
  InputRightElement,
  Icon,
  HStack,
  Link,
} from '@chakra-ui/react';
import './CustomersTable.css';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaPlus, FaSync, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import AlertPop from '../components/alertPop/AlertPop';
import { useForm } from 'react-hook-form';
import BackDropLoader from '../components/backDropLoader/BackDropLoader';
import { FiExternalLink } from 'react-icons/fi';

function CustomersTable({ navigation, route }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const jwtToken = localStorage.getItem('jwtToken');
  const [allCustomers, setAllCustomers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [URLSlug, setURLSlug] = useState('');
  const [URLSlugCheck, setURLSlugCheck] = useState('');
  const [versionOfCustomerAdmin, setVersionOfCustomerAdmin] = useState('');
  const [versionOfContentCreator, setVersionOfContentCreator] = useState('');
  const [versionOfUser, setVersionOfUser] = useState('');
  const [versionOfPresenter, setVersionOfPresenter] = useState('');

  // const URLSlugTrueRef = useRef('');
  // const URLSlugFalseRef = useRef('');
  // const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // console.log(jwtToken);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        'https://spotcloud.zone:3000/api/v1/spotcloud-app/list-all-customers',
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(res => {
        console.log('LIST OF CUSTOMER', res.data);
        if (res.data.code === 200) {
          console.log(res.data.data);
          setAllCustomers(res.data.data);
          setVersionOfCustomerAdmin(res.data.vCa);
          setVersionOfContentCreator(res.data.vCc);
          setVersionOfUser(res.data.vU);
          setVersionOfPresenter(res.data.vRa);
          setLoading(false);
        }
      })
      .catch(err => console.log(err));
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  function customerApproval(approvalVal, email, urlSlug) {
    console.log(approvalVal, email, 'email', 'url:', urlSlug);
  
    const sendApprovalRequest = (apiEndpoint) => {
      setLoading(true);
      axios
        .post(
          `https://spotcloud.zone:3000/api/v1/spotcloud-app/${apiEndpoint}`,
          {
            email: email,
            urlSlug: urlSlug,
          },
          {
            headers: {
              Authorization: jwtToken,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          setRefresh(!refresh);
          const action = approvalVal ? 'Unapproved' : 'Approved';
          const successMessage = `Customer ${action}!`;
  
          if (res.data.code === 200) {
            toast({
              title: 'Success',
              description: successMessage,
              status: 'success',
              duration: 3210,
              isClosable: true,
              position: 'bottom-right',
              variant: 'top-accent',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          toast({
            title: 'Error',
            description: 'An error occurred. Please try again.',
            status: 'error',
            duration: 3210,
            isClosable: true,
            position: 'bottom-right',
            variant: 'top-accent',
          });
        });
    };
  
    if (!approvalVal) {
      sendApprovalRequest('customer-approval-mail');
    } else {
      sendApprovalRequest('customer-unapproval-mail');
    }
  }
  

  const onSubmit = data => {
    console.log(data);
    if (URLSlugCheck === 'yes') {
      data['URLSlug'] = URLSlug;
      axios
        .post(
          'https://spotcloud.zone:3000/api/v1/spotcloud-app/add-customer',
          {
            ...data,
          },
          {
            headers: {
              Authorization: jwtToken,
            },
          }
        )
        .then(res => {
          console.log(res.data);
          if (res.data.code === 200) {
            setRefresh(!refresh);
            toast({
              title: 'Success',
              description: 'Customer Added!',
              status: 'success',
              duration: 3210,
              isClosable: true,
              position: 'bottom-right',
              variant: 'top-accent',
            });

            resetForm();

            onClose();
          }
        })
        .catch(err => {
          console.log(err);
          toast({
            title: 'Error',
            description: 'Could Not add Customer!',
            status: 'error',
            duration: 3210,
            isClosable: true,
            position: 'bottom-right',
            variant: 'top-accent',
          });
        });
    } else {
      toast({
        description: 'Type unique URL path for your app & check!',
        title: 'Error',
        status: 'error',
        duration: 3210,
        isClosable: true,
        position: 'bottom-right',
        variant: 'top-accent',
      });
    }
  };

  function resetForm() {
    reset();
    setURLSlug('');
    setURLSlugCheck('');
  }

  const handleURLSlugChange = e => {
    let newURLSlug = e.target.value;

    newURLSlug = newURLSlug.replace(/[^a-zA-Z0-9-]/g, '');

    setURLSlug(newURLSlug);

    if (newURLSlug === '') {
      setURLSlugCheck('');
      return;
    }

    axios
      .post(
        'https://spotcloud.zone:3000/api/v1/spotcloud-app/check-url-slug',
        {
          URLSlug: newURLSlug,
        },
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      )
      .then(res => {
        console.log(res.data);
        if (res.data.code === 200) {
          if (res.data.data.isURLSlugValid) {
            setURLSlugCheck('yes');
          } else {
            setURLSlugCheck('no');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const EachRow = allCustomers.map((user, index) => {
    return (
      <Tr key={index}>
        <Td className="tData">{index + 1}</Td>
        <Td className="tData">{user.firstName}</Td>
        <Td className="tData">{user.lastName}</Td>
        <Td className="tData">{user.email}</Td>
        <Td className="tData">{user.organizationName}</Td>
        <Td
          color={user.isEmailVerified ? 'green.500' : 'red.500'}
          className="tData"
          style={{ padding: '30px', paddingLeft: '50px' }}
        >
          {user.isEmailVerified ? (
            <FaCheckCircle size={30} />
          ) : (
            <FaTimesCircle size={30} />
          )}
        </Td>
        <Td className="tData">
          <Switch
            onChange={() => customerApproval(user.isApproved, user.email,user.URLSlug)}
            isChecked={user.isApproved}
            colorScheme="green"
            size="lg"
            isDisabled={!user.isEmailVerified && loading}
          />
        </Td>
        {/* <Td className="tData">{user.numberOfCustomerAdmins}</Td>
        <Td className="tData">{user.numberOfContentCreators}</Td>
        <Td className="tData">{user.numberOfUsers}</Td>
        <Td className="tData">{user.numberOfRemoteApps}</Td>
        <Td className="tData">{user.vCa}</Td>
        <Td className="tData">{user.vCc}</Td>
        <Td className="tData">{user.vU}</Td>
        <Td className="tData">{user.vRa}</Td> */}
        <Td className="tData">
          <Link
            href={`https://customers.spotcloud.zone/${user.URLSlug}`}
            isExternal
          >
            <HStack>
              <Text>{user.URLSlug}</Text> <FiExternalLink mx="2px" />
            </HStack>
          </Link>
        </Td>
      </Tr>
    );
  });

  return (
    <>
      {loading && <BackDropLoader />}

      {/* <Text textAlign="start" fontSize="2xl">
        All Customers
      </Text> */}
      <Stack mt={4} direction="row" spacing={4} align="center">
        <Button
          onClick={onOpen}
          leftIcon={<FaPlus />}
          colorScheme="linkedin"
          variant="solid"
        >
          Add Customer
        </Button>
        <Button
          onClick={handleRefresh}
          leftIcon={<FaSync />}
          colorScheme="blackAlpha"
          variant="solid"
        >
          Refresh
        </Button>
      </Stack>



      <Box mt={8} boxShadow="outline" p="6" rounded="md" bg="white">
        <TableContainer className="scrollbar" id="style-1">
          <Table size="md">
            <Thead>
              <Tr>
                <Th>S.No</Th>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Email</Th>
                <Th>Organization Name</Th>
                <Th>Email Verified</Th>
                <Th>Approved</Th>
                {/* <Th>
                  Number of <br />
                  Customer Admins
                </Th>
                <Th>
                  Number of <br />
                  Content Creators
                </Th>
                <Th>
                  Number of <br />
                  Users
                </Th>
                <Th>
                  Number of
                  <br /> Presenter Apps
                </Th>
                <Th>
                  Version of <br />
                  Customer Admins
                </Th>
                <Th>
                  Version of <br />
                  Content Creators
                </Th>
                <Th>
                  Version of <br />
                  Users
                </Th>
                <Th>
                  Version of
                  <br /> Remote Apps
                </Th> */}
                <Th>App URL Path </Th>
              </Tr>
            </Thead>
            <Tbody>{EachRow}</Tbody>
          </Table>
        </TableContainer>
      </Box>



      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Add New Customer</DrawerHeader>

          <DrawerBody pb={0} mt={0}>
            <Stack spacing="24px" direction="column">
              <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                <VStack pb={4} spacing={4} maxHeight="80vh" overflowY="scroll">
                  <Text fontSize="2xl">Customer Admin Details</Text>
                  <FormControl isRequired>
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      type="text"
                      id="firstName"
                      placeholder="First name"
                      {...register('firstName', {
                        required: 'Please enter first name',
                        minLength: {
                          value: 3,
                          message: 'Minimum 3 characters',
                        },
                        maxLength: 80,
                      })}
                    />
                    {errors.firstName && (
                      <AlertPop title={errors.firstName.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Last name</FormLabel>
                    <Input
                      background="white"
                      type="text"
                      placeholder="Last name"
                      {...register('lastName', {
                        minLength: {
                          value: 2,
                          message: 'Minimum 2 characters',
                        },
                      })}
                    />
                    {errors.lastName && (
                      <AlertPop title={errors.lastName.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      background="white"
                      type="email"
                      placeholder="Email address"
                      {...register('email', {
                        required: 'Please enter email address',
                      })}
                    />
                    <Flex width="100%">
                      <FormHelperText>
                        Email address will be verified.
                      </FormHelperText>
                    </Flex>
                    {errors.email && <AlertPop title={errors.email.message} />}
                  </FormControl>
                  <Text fontSize="2xl">Organization Details</Text>
                  <FormControl isRequired>
                    <FormLabel>Organization name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Organization name"
                      {...register('organizationName', {
                        required: 'Please enter organization name',
                        minLength: {
                          value: 2,
                          message: 'Minimum 2 characters',
                        },
                      })}
                    />
                    {errors.organizationName && (
                      <AlertPop title={errors.organizationName.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Street name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Street name"
                      {...register('address.streetName', {
                        required: 'Please enter street name',
                        minLength: {
                          value: 2,
                          message: 'Minimum 2 characters',
                        },
                      })}
                    />
                    {errors.address?.streetName && (
                      <AlertPop title={errors.address?.streetName.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Street number</FormLabel>
                    <Input
                      type="text"
                      placeholder="Street number"
                      {...register('address.streetNumber', {
                        required: 'Please enter street number',
                        minLength: { value: 1, message: 'Minimum 1 character' },
                      })}
                    />
                    {errors.address?.streetNumber && (
                      <AlertPop title={errors.address?.streetNumber.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Postal code</FormLabel>
                    <Input
                      type="text"
                      placeholder="Postal code"
                      {...register('address.postalCode', {
                        required: 'Please enter postal code',
                        minLength: {
                          value: 5,
                          message: 'Minimum 5 characters',
                        },
                      })}
                    />
                    {errors.address?.postalCode && (
                      <AlertPop title={errors.address?.postalCode.message} />
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>City</FormLabel>
                    <Input
                      type="text"
                      placeholder="City"
                      {...register('address.cityName', {
                        required: 'Please enter city',
                        minLength: {
                          value: 2,
                          message: 'Minimum 2 characters',
                        },
                      })}
                    />
                    {errors.address?.cityName && (
                      <AlertPop title={errors.address?.cityName.message} />
                    )}
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel htmlFor="URLSlug">
                      Unique URL Path for Your App
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        pr="6rem"
                        type="text"
                        id="URLSlug"
                        placeholder="Unique URL Path for Your App"
                        value={URLSlug}
                        onChange={handleURLSlugChange}
                      />
                    </InputGroup>
                    <Flex width="100%" flexDirection="row" alignItems="center">
                      <FormHelperText>
                        For example: "name-of-your-organization"{' '}
                      </FormHelperText>
                      {URLSlugCheck === 'yes' && (
                        <FormHelperText ml={3} color="green">
                          <HStack>
                            {' '}
                            <Icon as={FaCheckCircle} />
                            <Text>URL path available</Text>
                          </HStack>
                        </FormHelperText>
                      )}
                      {URLSlugCheck === 'no' && (
                        <FormHelperText ml={3} color="red">
                          <HStack>
                            {' '}
                            <Icon as={FaTimesCircle} />
                            <Text> URL path not available</Text>
                          </HStack>
                        </FormHelperText>
                      )}
                    </Flex>
                  </FormControl>
                </VStack>
              </form>
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={URLSlug.length === 0}
              type="submit"
              form="my-form"
              colorScheme="blue"
            >
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CustomersTable;
