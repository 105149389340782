import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ isLoggedIn, children }) {
  // console.log(isLoggedIn);
  // console.log('protected route - out');
  if (!isLoggedIn) {
    // console.log('protected route - in');
    return <Navigate to="/" replace />;
  }
  return children;
}

export default ProtectedRoute;
