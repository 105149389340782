import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  // HStack,
  // Link,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertPop from './../components/alertPop/AlertPop';

export default function Login({ setIsLoggedIn }) {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const { state } = useLocation();
  const navigate = useNavigate();
  // console.log(state);

  // if (state?.logout) {
  //   setIsLoggedIn(false);
  //   // localStorage.clear();
  // }

  function tryLogin(data) {
    setIsLoading(true);
    console.log(data);

    axios
      .post('https://spotcloud.zone:3000/api/v1/spotcloud-app/admin-login', {
        ...data,
      })
      .then(res => {
        console.log(data);

        reset({
          email: '',
          password: '',
        });

        toast({
          title: 'Success',
          description: 'please check your email for OTP',
          status: 'success',
          duration: 3210,
          isClosable: true,
          position: 'bottom-right',
          variant: 'top-accent',
        });
        setIsLoading(false);

        navigate('/2fa', { state: { email: data.email } });
      })
      .catch(res => {
        setIsLoading(false);
        console.log(res);
        toast({
          title: 'Error',
          description: 'please check login credentials & try again!',
          status: 'error',
          duration: 3210,
          isClosable: true,
          position: 'bottom-right',
          variant: 'top-accent',
        });
      });
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} py={12} px={6}>
        <Box
          maxW="sm"
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack mb={12} align={'center'}>
            <Heading fontSize={'4xl'} textAlign={'center'}>
              Spotcloud Admin
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Login Page
            </Text>
          </Stack>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                {...register('email', {
                  required: 'Please enter email address',
                })}
              />
              {errors.email && <AlertPop title={errors.email.message} />}
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: 'Please enter password',
                  })}
                />

                <InputRightElement h={'full'}>
                  <Button
                    size="sm"
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <FiEye /> : <FiEyeOff />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.password && <AlertPop title={errors.password.message} />}
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={handleSubmit(tryLogin)}
                isLoading={isLoading}
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
