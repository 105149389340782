import {
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';

export default function BackDropLoader() {
  const OverlayOne = () => (
    <ModalOverlay bg="blackAlpha.200" backdropFilter="blur(10px) " />
  );

  return (
    <>
      <Modal isCentered isOpen={true}>
        <OverlayOne />
        <ModalContent boxShadow="none" bg="none">
          <HStack justify="center">
            <Spinner
              bg="none"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </HStack>
        </ModalContent>
      </Modal>
    </>
  );
}
