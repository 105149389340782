import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import Login from './pages/Login';
import OTP from './pages/OTP';
import NotFound from './pages/NotFound';
import DashboardLayout from './pages/DashboardLayout';
import CustomersTable from './pages/CustomersTable';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './pages/ProtectedRoute';
import "../src/pages/style.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   let token = localStorage.getItem('jwtToken');
  //   console.log(token);
  //   if (token != null) {
  //     setIsLoggedIn(true);
  //     console.log('token trueee');
  //   }
  // }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="2fa" element={<OTP setIsLoggedIn={setIsLoggedIn} />} />
          <Route
            path="/all-customers"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <DashboardLayout setIsLoggedIn={setIsLoggedIn}>
                  <CustomersTable />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
