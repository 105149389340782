import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AlertPop from '../components/alertPop/AlertPop';
import axios from 'axios';
import { useState } from 'react';

export default function OTP({ setIsLoggedIn }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function otpSubmit(data) {
    setIsLoading(true);
    // data['email'] = 'premkumar@bloomsmobility.com';
    data['email'] = state?.email;
    console.log(data);

    axios
      .post('https://spotcloud.zone:3000/api/v1/spotcloud-app/verify-otp', {
        ...data,
      })
      .then(res => {
        console.log(res);
        localStorage.setItem('jwtToken', res.data.data.token);
        setIsLoggedIn(true);
        setIsLoading(false);
        navigate('/all-customers');
      })
      .catch(res => {
        console.log(res);
        toast({
          title: 'Error',
          description: 'please check OTP & try again!',
          status: 'error',
          duration: 3210,
          isClosable: true,
          position: 'bottom-right',
          variant: 'top-accent',
        });
        setIsLoading(false);
      });
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          2 Factor Authentication
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          check your email for OTP
        </Text>
        <FormControl id="email">
          <Input
            placeholder="Enter OTP here..."
            _placeholder={{ color: 'gray.500' }}
            type="text"
            {...register('otp', {
              required: 'Please enter OTP ',
            })}
          />
          {errors.otp && <AlertPop title={errors.otp.message} />}
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={handleSubmit(otpSubmit)}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
